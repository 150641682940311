import { ref } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios';

export const useStorageStore = defineStore('storage', () => {

    const data = ref([]);
    const dataAll = ref([]);

    const selectedStorage = ref({_id:'root',name:'root'});

    async function fetch(filter) {
        
        filter = filter ? filter : selectedStorage.value._id;

        console.log('FILTER:',filter)

        let response = await axios.get(`/api/v1/storage/?filter=${filter}`);

        data.value = response.data;
    }

    async function fetchAll(filter) {

        filter = filter ? filter : 'root';

        let response = await axios.get(`/api/v1/storage/`);

        dataAll.value = response.data;
        dataAll.value.push({_id:'root',name:'root'});
    }

    const getName = (storageId)=>{
        let storage = dataAll.value.find((dd)=>{
            if(dd._id == storageId){
                return true;
            }
        })
        console.log('storage storage storage',storage)
        if(storage){
            return storage.name
        }
        return 'root';
    }

    const get = (storageId)=>{
          let storage = dataAll.value.find((dd)=>{
            if(dd._id == storageId){
                return true;
            }
        })
        if(storage){
            return storage
        }
        return {_id:'root',name:'root'};
    }

    return { fetch, data, fetchAll, dataAll, selectedStorage,get,getName }
})
