<script setup>
import { ref,unref } from 'vue';
import axios from 'axios';
import Modal from 'bootstrap/js/dist/modal';
import StorageSelector from '@/components/StorageSelector.vue';
import {usePartStore} from '@/stores/part'
import {useStorageStore} from '@/stores/storage'

const defaultFormValues = {
    name:'',
    description:'',
    part_number:'',
    reference:'',
    location:'',
    qty:0,
    min_qty:0,
    keyworkds:''
}

const addPartModalRef = ref();
const partStore = usePartStore();
const storageStore = useStorageStore();
const newPartData = ref(structuredClone(defaultFormValues));
let  addPartModal = null;

const openAddModal = () => {
    const options = {};
    addPartModal = new Modal('#staticBackdrop', options)
    newPartData.value.location = storageStore.selectedStorage._id;
    addPartModal.show();
}

const addNewPartAndContinue = async ()=>{
    let response = await axios.post('/api/v1/part/', newPartData.value);
    newPartData.value.name = '';

    try {
        await partStore.fetch(storageStore.selectedStorage._id);
        await storageStore.fetch(storageStore.selectedStorage._id);
    }
    catch (err) {
        console.error(err);
    }
}

const addNewPart = async ()=>{

    let response = await axios.post('/api/v1/part/',newPartData.value);
    newPartData.value = structuredClone(defaultFormValues);

    try{
        await partStore.fetch(storageStore.selectedStorage._id);
        await storageStore.fetch(storageStore.selectedStorage._id);
    }
    catch(err){
        console.error(err);
    }

    addPartModal.hide();
}
</script>
<template lang="pug">

button.btn.btn-primary(@click="openAddModal()") 
    i.bi.bi-cpu.me-2
    | Add Part

div.modal.fade#staticBackdrop( ref="addPartModalRef" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true")
    div.modal-dialog
        div.modal-content
            div.modal-header
                h1.modal-title.fs-5#staticBackdropLabel Add New Part
                button.btn-close(type="button" data-bs-dismiss="modal" aria-label="Close")

            div.modal-body
                div.mb-3
                    label.form-label(for="newPartName") Name
                    input#newPartName.form-control(v-model="newPartData.name")
                div.mb-3
                    label.form-label(for="newPartName") Description
                    textarea#newPartDescription.form-control(rows="3" v-model="newPartData.description")
                div.mb-3
                    label.form-label(for="newPartNumber") Part Number
                    input#newPartNumber.form-control(v-model="newPartData.part_number")
                div.mb-3
                    label.form-label(for="newPartReference") Reference
                    input#newPartReference.form-control(v-model="newPartData.reference")
                div.mb-3
                    div.row
                        div.col
                            StorageSelector(label="Location" mode="full" v-model="newPartData.location")
                div.mb-3
                    div.row
                        div.col-4
                            label.form-label(for="newPartName") Initial Quantity
                            input#newPartName.form-control(type="number" value="0" v-model="newPartData.qty")
                        div.col-4
                            label.form-label(for="newPartName") Minimum Quantity
                            input#newPartName.form-control(type="number" value="0" v-model="newPartData.min_qty")

                div.mb-3
                    label.form-label(for="newPartName") Keywords
                    input#newPartName.form-control(v-model="newPartData.keywords")

                div.mb-3
                    label.form-label(for="formFileMultiple") Attachments
                    input#formFileMultiple.form-control(type="file" multiple)

            div.modal-footer
                button.btn.btn-primary(type="button" @click="addNewPart()") Add
                button.btn.btn-secondary(type="button" @click="addNewPartAndContinue()") Add and Continue
                button.btn.btn-outline-secondary(type="button" data-bs-dismiss="modal") Cancel
</template>