<script setup>
import { ref, onMounted,watch } from 'vue';

import PartContainer from '@/components/PartContainer.vue'
// import SearchResultsContainer from '@/components/SearchResultsContainer.vue'
import StorageContainer from '@/components/StorageContainer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import AddPartModal from '@/components/AddPartModal.vue'
import AddStorageModal from '../components/AddStorageModal.vue';

import { usePartStore } from '@/stores/part'
import { useStorageStore } from '@/stores/storage'

const partStore = usePartStore();
const storageStore = useStorageStore();

const breadcrumbs = ref([]);

onMounted(async ()=>{
    
    let storageId = storageStore.selectedStorage._id;
    // let storageId = 'root';

    const urlParams = new URLSearchParams(window.location.search);
    const filter = urlParams.get('storage');

    if(filter){
        storageId = filter;
    }
   
    // await partStore.fetch(storageId);
    // await storageStore.fetch(storageId);
    await storageStore.fetchAll();

    await loadStorage(storageStore.selectedStorage._id);
    // await loadStorage(storageId);
})

watch(()=>storageStore.selectedStorage,async (storage)=>{

    console.log('selectedStorage Changed',storage)
    await partStore.fetch(storage._id);
})

const openDetailsModal = (partId) => {
    const options = {};
    const myModalAlternative = new Modal('#partDetailsModal', options)

    myModalAlternative.show();
}

const loadStorage = async (storageId)=>{
 await partStore.fetch(storageId);
    let storage = storageStore.get(storageId);
//    debugger;
    if((breadcrumbs.value.length == 0 && storageId != 'root')|| storageStore.selectedStorage._id != storageId){

        breadcrumbs.value.push(storage);    
    }
    storageStore.selectedStorage = {...storage};
    await storageStore.fetch(storage._id)
}

const resetFilter = async ()=>{
    await loadStorage('root');
    // await loadStorage({_id:'root',name:'root'});
    breadcrumbs.value = [];
}

const pop = async (n)=>{
   
    breadcrumbs.value.splice(n,breadcrumbs.value.length-n);
    storageStore.selectedStorage = breadcrumbs.value[breadcrumbs.value.length-1];
    await storageStore.fetch(storageStore.selectedStorage._id)
}

</script>

<template lang="pug">
div.container-fluid
    div.container-fluid
        div.row
            div.col
                Breadcrumbs(:history="breadcrumbs" @load-root="resetFilter()" @pop="pop")
            div.col.col-auto.d-flex.align-items-center
                AddPartModal(ref="addPartModalRef")
            div.col.col-auto.d-flex.align-items-center
                AddStorageModal(ref="addStorageModalRef")
    StorageContainer(@loadStorage="loadStorage")
    PartContainer(@loadStorage="loadStorage")
    //- SearchResultsContainer()
</template>


<style>
.ds-containerName {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    overflow: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.ds-containerName::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.ds-containerName h3 {
    position: relative;
    top: -5px;
}
</style>