<script setup>
const props = defineProps(['part'])
defineEmits(['edit','delete'])
import ColorPuck from '@/components/ColorPuck.vue';
import {usePartStore} from '@/stores/part';
import {useStorageStore} from '@/stores/storage'
const partStore = usePartStore();
const storageStore = useStorageStore();
</script>

<template lang="pug">
div.col-12.col-md-6.col-lg-4.col-xxl-3
    div.card.mb-3
        div.card-body
            div.row
                div.col-auto
                    ColorPuck(:color1="props.part.color1" :color2="props.part.color2")
                div.ps-0.col.ds-containerName
                    h3.mb-0 {{ props.part.name }}
                div.col-auto
                    div.dropdown
                        a.link-secondary(href="#" style="font-size:1.2em;" data-bs-toggle="dropdown")
                            i.bi.bi-three-dots-vertical
                        ul.dropdown-menu 
                            li  
                                a.dropdown-item(href="#" @click.prevent.stop="$emit('edit',props.part._id)") Edit
                            li
                                a.dropdown-item(href="#" @click.prevent.stop="$emit('delete',props.part._id)") Delete
                            //- li
                            //-     a.dropdown-item(href="#" @click.prevent.stop="openDetailsModal(props.part._id)") Details
            div.mt-2 {{ props.part.description }}
        div.card-footer
            div.row
                div.col 
                    a.link-secondary(href="#" @click="$emit('loadStorage',props.part.location)")
                        b {{ storageStore.getName(props.part.location) }}
                div.col-auto Qty: {{ props.part.qty }}
</template>