import { ref } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios';

export const usePartStore = defineStore('part', () => {

    const data = ref([]);

    async function fetch(storageId) {

        storageId = storageId ? storageId : 'root';

        let response = await axios.get(`/api/v1/part/?location=${storageId}`);

        data.value = response.data;
    }

    return { data, fetch }
})
