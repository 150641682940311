
<script setup>
import { onMounted, ref } from 'vue';
import Modal from 'bootstrap/js/dist/modal';
import axios from 'axios';
import {useStorageStore} from '@/stores/storage'
import StorageSelector from '@/components/StorageSelector.vue';

const storageStore = useStorageStore();
const storageModal = ref(null);

let modal = null;

const openAddModal = () => {
    const options = { backdrop: 'static'};
    newStorageData.value.parentId = storageStore.selectedStorage._id;
    modal = new Modal('#addStorageModal', options);
    modal.show();
}

const defaultData = {
    name:'',
    uniqueId:'',
    description:'',
    parentId:null,
    keywords:'',
    color1:'#000000',
    color2:'#000000'
}

const newStorageData = ref({});


const addNewStorage = async () => {

    let response = await axios.post('/api/v1/storage/',newStorageData.value);

    newStorageData.value = structuredClone(defaultData);
    await storageStore.fetch();
    await storageStore.fetchAll();
    modal.hide();
}


onMounted(async ()=>{
    
    storageModal.value.addEventListener('hidden.bs.modal',(event)=>{    
        newStorageData.value = structuredClone(defaultData);
    });
})
</script>


<template lang="pug">
button.btn.btn-primary(@click="openAddModal()") 
    i.bi.bi-inbox.me-2
    | Add Storage

div.modal.fade#addStorageModal(ref="storageModal" tabindex="-1" aria-labelledby="addStorageModalLabel" aria-hidden="true")
    div.modal-dialog
        div.modal-content
            div.modal-header
                h1.modal-title.fs-5#addStorageModalLabel Add New Storage
                button.btn-close(type="button" data-bs-dismiss="modal" aria-label="Close")

            div.modal-body
                div.mb-3
                    label.form-label(for="newPartName") Name
                    input#newPartName.form-control(v-model="newStorageData.name")
                div.mb-3
                    label.form-label(for="newPartUniqueId") Unique Id
                    input#newPartUniqueId.form-control(v-model="newStorageData.uniqueId")
                div.mb-3
                    label.form-label(for="newPartName") Description
                    textarea#newPartDescription.form-control(rows="3" v-model="newStorageData.description")
                div.mb-3
                    div.row
                        div.col
                            StorageSelector(v-model="newStorageData.parentId" mode="simple")
                div.mb-3
                    div.row
                        div.col-6
                            label.form-label(for="newPartColor1") Color #1
                            input#newPartColor1.form-control.form-control-color(type="color" v-model="newStorageData.color1")
                        div.col-6
                            label.form-label(for="newPartColor1") Color #2
                            input#newPartColor2.form-control.form-control-color(type="color" v-model="newStorageData.color2")
                
                div.mb-3
                    label.form-label(for="newPartName") Keywords
                    input#newPartName.form-control(v-model="newStorageData.keywords")

            div.modal-footer
                button.btn.btn-primary(type="button" @click="addNewStorage()") Add
                button.btn.btn-outline-secondary(type="button" data-bs-dismiss="modal") Cancel

</template>