<script setup>
import axios from 'axios';
import { ref } from 'vue';

const reportData = ref('');
const generateReport = async (type)=>{
    const res = await axios.get(`/api/v1/reports/${type}`);
    reportData.value = res.data;
}
</script>

<template lang="pug">
div.container.mt-3
    div.row
        div.col
            button.btn.btn-primary.me-3(@click="generateReport('all')") All Parts
            button.btn.btn-primary(@click="generateReport('lowstock')") Low in Stock
    div.w100.mt-3
        template(v-if="reportData.length == 0")
            | No Values
        template(v-else)
            table.table.table-sm
                thead
                    tr
                        th Name
                        th Location
                        th Quantity
                        th Miniumum Quantity 
                        th Keywords
                        th Description
                tbody
                    template(v-for="part in reportData")
                        tr
                            td {{ part.name }}
                            td {{ part.location_path_str }}
                            td {{ part.qty }}
                            td {{ part.min_qty }}
                            td {{ part.keywords }}
                            td {{ part.desc }}

</template>