
<script setup>
import { ref } from 'vue'
import Modal from 'bootstrap/js/dist/modal';

const props = defineProps(['part_id'])


const openAddModal = () => {
    const options = {};
    const myModalAlternative = new Modal('#partDetailsModal', options)

    myModalAlternative.show();
}

const filelist = ref([
    {name:'Dataasheet 1.pdf',link:'http://danicstonge.com'},
    {name:'Dataasheet 2.pdf',link:'http://danicstonge.com'},
    {name:'Dataasheet 4.pdf',link:'http://danicstonge.com'},
])
</script>

<template lang="pug">

div.modal.fade#partDetailsModal(data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true")
    div.modal-dialog
        div.modal-content
            div.modal-header
                h1.modal-title.fs-5#staticBackdropLabel Part Details
                button.btn-close(type="button" data-bs-dismiss="modal" aria-label="Close")

            div.modal-body
                div.mb-3
                    label.form-label(for="newPartName") Name
                    input#newPartName.form-control(readonly value="part name here")
                div.mb-3
                    label.form-label(for="newPartName") Description
                    textarea#newPartDescription.form-control(rows="3")
                div.mb-3
                    div.row
                        div.col-auto
                            label.form-label(for="newPartColor1") Color #1
                            input#newPartColor1.form-control.form-control-color(type="color")
                        div.col-auto
                            label.form-label(for="newPartColor1") Color #2
                            input#newPartColor2.form-control.form-control-color(type="color")
                div.mb-3
                    div.row
                        div.col
                            label.form-label(for="newPartName") Location 
                            select.form-select
                                option(value="1") Box 1
                                option(value="2") Box 2
                        div.col

                div.mb-3
                    h6 History
                    hr
                div.mb-3
                    h6 Attachements
                    ul.list-group 
                        template(v-for="file in filelist" :key="file.name")
                            li.list-group-item 
                                div.row
                                    div.col-auto
                                        i.bi.bi-file-earmark-pdf-fill
                                    div.col
                                        a(:href="file.link")
                                            | {{ file.name }}
                                    div.col-auto
                                        a.me-3(href="#")
                                            i.bi.bi-download
                                        a(href="#")
                                            i.bi.bi-trash-fill

            div.modal-footer
                button.btn.btn-outline-secondary(type="button" data-bs-dismiss="modal") Close


</template>