<script setup>
import { ref,onMounted,onUpdated } from 'vue';
import {useStorageStore} from '@/stores/storage'
import {usePartStore} from '@/stores/part'
import {Tooltip} from 'bootstrap';
import axios from 'axios';
import EditStorageModal from './EditStorageModal.vue';
import ColorPuck from '@/components/ColorPuck.vue';

const emit = defineEmits(['loadStorage'])


const partStore = usePartStore();
const storageStore = useStorageStore();

const currentFilter = ref('');

const storageList = ref([]);

const deleteStorage = async (storageId)=>{
    if(window.confirm("Do you really want to delete this storage bin?")){
        try{
            await axios.delete(`/api/v1/storage/${storageId}`);
        }
        catch(err){
        
        }
        await storageStore.fetch();
        await partStore.fetch();
    }
}
const loadParentStorage = (storageId)=>{
    alert('loadParentStorage')
}
const editStorage = (storageId)=>{
    alert('editStorage')
}

onUpdated(()=>{
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
    console.log('tooltipList',tooltipList)
})

const printStorage = (storageId)=>{

    // alert(`printing ${storageId}`)
    let url = `/api/v1/storage/summary/${storageId}`;
    let windowName = 'storageSummary';
    let windowSpecs = 'menubar=yes,status=yes';
    window.open(url,windowName,windowSpecs)
}
</script>

<template lang="pug">
template(v-if="storageStore.data.length != 0")
    div.container-fluid.p-3
        div.list-group
            template(v-for="storage in storageStore.data" :key="storage._id")
                a.list-group-item.list-group-item-action.pe-0(href="#" @click="$emit('loadStorage',storage._id)" aria-current="true")
                    div(class="d-flex w-100 justify-content-between")
                        div.row.w-100
                            div.col-auto
                                ColorPuck(:color1="storage.color1" :color2="storage.color2")
                            div.col.w-100.pe-0
                                div.row.w-100.me-0
                                    div.col
                                        h5.mb-0 {{ storage.name }}
                                    //- template(v-if="storage.parentId")
                                    //-     div.col-auto.pe-0
                                    //-         button.btn.btn-outline-secondary.ds-button(@click.stop.prevent="loadParentStorage(storage._id)" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-title="Load Parent")
                                    //-             i.bi.bi-archive-fill
                                    div.col-auto.pe-0.text-secondary
                                        template(v-if="storage.numberOfParts < 2")
                                            div {{ storage.numberOfParts }} Part
                                        template(v-else)
                                            div {{ storage.numberOfParts }} Parts
                                    div.col-auto.pe-0.text-secondary
                                        template(v-if="storage.numberOfChilds < 2")
                                            div {{ storage.numberOfChilds }} Storage Bin
                                        template(v-else)
                                            div {{ storage.numberOfChilds }} Storage Bins
                                    div.col-auto.pe-0
                                        button.btn.btn-outline-secondary.ds-button(@click.stop.prevent="printStorage(storage._id)" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-title="Print")
                                            i.bi.bi-printer
                                    div.col-auto.pe-0
                                        //- button.btn.btn-outline-secondary.ds-button(@click.stop.prevent="editStorage(storage._id)" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-title="Edit")
                                        button.btn.btn-outline-secondary.ds-button(@click.stop.prevent="$refs.editStorageModal.edit(storage._id)" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-title="Edit")
                                            i.bi.bi-pencil-fill
                                    div.col-auto.pe-0
                                        button.btn.btn-outline-danger.ds-button(@click.stop.prevent="deleteStorage(storage._id)" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-title="Delete")
                                            i.bi.bi-trash-fill
                                p.text-body-tertiary(class="mb-0") {{ storage.desc }}
                                template(v-if="storage.keywords")
                                    small.text-secondary.fst-italic Keywords: {{ storage.keywords }}
EditStorageModal(ref="editStorageModal")
</template>

<style scoped>
.ds-button{
    width: 25px;
    height: 25px;
    padding: 0;
}
</style>