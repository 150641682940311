<script setup>
import {useStorageStore} from '@/stores/storage'
import {ref,unref,onMounted,onUnmounted,watch} from 'vue'
import Dropdown from 'bootstrap/js/dist/dropdown';

let drop = null;
const selectedValue = ref('');
const storageStore = useStorageStore();
const storageFiltered = ref([]);
const searchField = ref('');
const active_page = ref('main');

const props = defineProps({
    modelValue:{
        type:String
    },
    label:{
        type:String,
        default:'Parent'
    },
    mode:{
        type:String,
        default:'simple' // full or simple
    }
});

const emit = defineEmits(['update:modelValue'])

const selectStorage = (storageId,name)=>{
console.log('selectStorage',storageId,name)
    selectedValue.value = name;
    emit('update:modelValue', storageId);
    drop.hide();
}
const asdf = ref(null);


watch(()=>props.modelValue,(newValue)=>{
    
    console.log('newValue',newValue);
    
    if(!newValue){
        selectedValue.value = 'root';
    }
    else{
         selectedValue.value = storageStore.getName(newValue)
    }
})


onMounted(async ()=>{

    drop = new Dropdown(asdf.value)
    
    asdf.value.addEventListener('hidden.bs.dropdown',(event)=>{
        console.log('hidden.bs.dropdown',event)
        searchField.value = '';
    });

    asdf.value.addEventListener('show.bs.dropdown',(event)=>{
        console.log('show.bs.dropdown',event)
        storageFiltered.value = storageStore.dataAll;
    });

    document.addEventListener("click", myFunction);

    
})

function myFunction(event){
    console.log('ParentNode',asdf.value.parentNode)
    console.log('target',event.target)

    if(!asdf.value.parentNode.contains(event.target)){
        drop.hide();
    }
}

onUnmounted(async ()=>{
    document.removeEventListener("click", myFunction);
})

watch(searchField,(newValue)=>{
    console.log('searchField',newValue)
    storageFiltered.value = storageStore.dataAll.filter((storageObj)=>{
        if(storageObj.name !== null){
            return storageObj.name.toLowerCase().includes(newValue.toLowerCase());
        }
        else if(storageObj._id == 'root'){
            return true;
        }
        return false;
    })

    console.log('storageFiltered.value',storageFiltered.value)
})
</script>

<template lang="pug">

div.dropdown
    label.form-label(for="newPartName") {{ props.label }} 
    a.form-select(href="#" ref="asdf" @click.stop="drop.toggle()" data-bs-auto-close="outside" aria-expanded="false" style="text-decoration:none;")
        input.form-control-plaintext(type="text" readonly :value="selectedValue" style="padding:0;cursor:pointer;")
    div.dropdown-menu(style="width:300px;")
        
        div(:style="{display:active_page=='main'?'block':'none'}")
            h6.text-center Select Storage
            div.dropdown-divider
            div.mx-3
                div.input-group
                    input.form-control(type="text" v-model="searchField")
                    span.input-group-text 
                        i.bi.bi-search
                        
            div.dropdown-divider.mb-0
            div.list-group.list-group-flush(style="max-height: 164px;overflow-y: auto;")
                template(v-if="storageFiltered.length == 0")
                    div.list-group-item.list-group-item-action No Storage Available
                template(v-for="storage in storageFiltered")
                    button.list-group-item.list-group-item-action(type="button" @click="selectStorage(storage._id,storage.name)") {{ storage.name }}
            //- template(v-if="props.mode=='full'")
            //-     div.dropdown-divider.mt-0
            //-     a.dropdown-item(href="#" @click="active_page='new_storage'") Create New Storage
            //-     a.dropdown-item(href="#") Manage Storage

        div(:style="{display:active_page=='new_storage'?'block':'none'}")
            h6.text-center.mb-0 Create New Storage
            div.dropdown-divider
            div.row.mx-1
                div.col-12
                    div.mb-3
                        label.form-label(for="newPartName") Name
                        input#newPartName.form-control
                div.col-6
                    label.form-label(for="newPartColor1") Color #1
                    input#newPartColor1.form-control.form-control-color(type="color")
                div.col-6
                    label.form-label(for="newPartColor1") Color #2
                    input#newPartColor2.form-control.form-control-color(type="color")
            div.dropdown-divider.mb-0
            div.px-3
                label.form-label(for="newPartName") Location
                div.input-group
                    input.form-control(type="text")
                    span.input-group-text 
                        i.bi.bi-search
                            
            div.dropdown-divider.mb-0
            div.row
                div.col-12
                    div.list-group.list-group-flush(style="max-height: 164px;overflow-y: auto;")
                        button.list-group-item.list-group-item-action(type="button") B1-T3
                        button.list-group-item.list-group-item-action(type="button") B1-T4
                        button.list-group-item.list-group-item-action(type="button") B1-T5
                        button.list-group-item.list-group-item-action(type="button") B1-T5
                        button.list-group-item.list-group-item-action(type="button") B1-T5
                        //- button.list-group-item.list-group-item-action(type="button") B1-T5
                        //- button.list-group-item.list-group-item-action(type="button") B1-T5
            div.dropdown-divider.mt-0
            div.mx-3.mt-3.mb-2.d-flex.justify-content-between
                button.btn.btn-primary Add
                button.btn.btn-outline-secondary(@click="active_page='main'") Cancel
</template>

<style scoped>

</style>