import { createRouter, createWebHashHistory  ,createWebHistory} from 'vue-router'
import MainView from '../views/MainView.vue'
import Search from '../views/Search.vue'
import Reports from '../views/Reports.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'main',
      component: MainView
    },
     {
      path: '/search',
      name: 'search',
      component: Search
    },
    {
      path: '/reports',
      name: 'reports',
      component: Reports
    }
  ]
})

export default router
