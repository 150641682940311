<script setup>
import { ref, watch } from 'vue';
import { RouterLink, routerKey } from 'vue-router';
import axios from 'axios';
import EditPartModal from '@/components/EditPartModal.vue';
import Part from '@/components/Part.vue'
import {usePartStore} from '@/stores/part';
import {useStorageStore} from '@/stores/storage'
import router from '@/router'
const partStore = usePartStore();
const storageStore = useStorageStore();

const searchQuery = ref('');

const editPartId = ref('');

const parts = ref([])
const storages = ref([]);

watch(searchQuery,async (newValue)=>{
    // console.log('searchQuery',newValue);

    // let response = await axios.get(`/api/v1/part/?filter=${newValue}`)
    // console.log(response)
    // parts.value = response.data;

    fetchParts();
})

const fetchParts = async ()=>{

    if(searchQuery.value != ""){
        console.log('searchQuery',searchQuery.value);

        let response = await axios.get(`/api/v1/part/?filter=${searchQuery.value}`)
        console.log(response)
        parts.value = response.data;
    }
    else{
        parts.value = [];
    }
}

const clearResults = ()=>{
    searchQuery.value = '';
}

const deletePart = async (partId) => {
    if(window.confirm("Do you really want to delete this part?")){
        console.log(`Delete part ${partId}`)
    
        try{
            await axios.delete(`/api/v1/part/${partId}`);
        }
        catch(err){

        }
        await fetchParts();
        await partStore.fetch(storageStore.selectedStorage._id);
        await storageStore.fetchAll();
    }
}

const editPart = (partId)=>{

    editPartId.value = partId;
}

const editDone = async ()=>{
    editPartId.value = '';
    await fetchParts();
}


const loadStorage = (storageId)=>{
    storageStore.selectedStorage._id = storageId;
    router.push({path:'/'});
}
</script>

<template lang="pug">
div.container.mt-3.mb-3
    div.row.w-100
        div.col.w-100
            form.d-flex(role="search")
                input.form-control(type="search" placeholder="Search" aria-label="Search" v-model="searchQuery")

div.container-fluid
    template(v-if="parts.length")
        div.row
            template(v-for="part in parts")
                Part(:part="part" @edit="editPart" @delete="deletePart" @loadStorage="loadStorage")
    template(v-else)
        div.card.text-center.p-5
            b Nothing Found

div.container.mt-3.mb-3
    div.row.w-100
        div.col.w-100
            button.btn.btn-danger.w-100(@click.stop.prevent="clearResults()") Clear Results

EditPartModal(ref="editPartModal" :part-id="editPartId" @done="editDone")
</template>