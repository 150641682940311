<script setup>
import { ref, onMounted } from 'vue';
import ColorPuck from '@/components/ColorPuck.vue';
import DetailsPartModal from '@/components/DetailsPartModal.vue';
import EditPartModal from '@/components/EditPartModal.vue';
import Part from '@/components/Part.vue'
import {usePartStore} from '@/stores/part';
import {useStorageStore} from '@/stores/storage'
import Modal from 'bootstrap/js/dist/modal';
import axios from 'axios';

const emit = defineEmits(['loadStorage','done'])

const partStore = usePartStore();
const storageStore = useStorageStore();

const editPartId = ref('');

const props = defineProps({
    location:{
        type:String,
        default:'root'
    }
});

onMounted(()=>{

});

const openDetailsModal = (partId) => {
    const options = {};
    const myModalAlternative = new Modal('#partDetailsModal', options);
    myModalAlternative.show();
}

const deletePart = async (partId) => {
    if(window.confirm("Do you really want to delete this part?")){
        console.log(`Delete part ${partId}`)
    
        try{
            await axios.delete(`/api/v1/part/${partId}`);
        }
        catch(err){

        }

        await partStore.fetch(storageStore.selectedStorage._id);
        await storageStore.fetchAll();
    }
}

const editPart = (partId)=>{

    editPartId.value = partId;
}

const doneEdit = async ()=>{

    await partStore.fetch(storageStore.selectedStorage._id);
    editPartId.value = '';
}

const loadStorage = (storageId)=>{
    emit('loadStorage',storageId)
}
</script>

<template lang="pug">

div.container-fluid.p-3
    template(v-if="partStore.data.length == 0")
        div.card.text-center.p-5
            b Empty
    template(v-else)
        div.row
            template(v-for="container in partStore.data" :key="container._id")
                Part(:part="container" @edit="editPart" @delete="deletePart" @loadStorage="loadStorage")
                //- Part(:part="container" @edit="$refs.editPartModal.editea" @delete="deletePart")

DetailsPartModal
EditPartModal(ref="editPartModal" :part-id="editPartId" @done="doneEdit")
</template>


<style>
.ds-containerName {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    overflow: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.ds-containerName::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.ds-containerName h3 {
    position: relative;
    top: -5px;
}
</style>