<script setup>
import { ref,unref,onMounted, onBeforeUnmount,watch } from 'vue';
import axios from 'axios';
import Modal from 'bootstrap/js/dist/modal';
import StorageSelector from '@/components/StorageSelector.vue';
import {usePartStore} from '@/stores/part'
import {useStorageStore} from '@/stores/storage'

const props = defineProps(['partId']);
const emit = defineEmits(['done']);

const defaultFormValues = {
    name:'',
    description:'',
    part_number:'',
    reference:'',
    location:'',
    qty:0,
    min_qty:0,
    keyworkds:''
}

const addPartModalRef = ref();
const partStore = usePartStore();
const storageStore = useStorageStore();
const newPartData = ref(structuredClone(defaultFormValues));
let  addPartModal = null;

const edit = async (partId)=>{

    if(partId != ''){
        console.log('partId',partId);
        console.log('newPartData.value',newPartData.value)
        let response = await axios.get(`/api/v1/part/${partId}`);
        newPartData.value = response.data;
        console.log('newPartData.value',newPartData.value)
        addPartModal.show();
    }
}

// defineExpose({edit});
const openAddModal = () => {
    const options = {};
    addPartModal = new Modal('#editPartModal', options)
    newPartData.value.location = storageStore.selectedStorage._id;
    addPartModal.show();
}

watch(()=>props.partId,edit)

const addNewPart = async ()=>{
    
    let data = {...newPartData.value};
    delete data._id

    let response = await axios.put(`/api/v1/part/${newPartData.value._id}`,data);
    newPartData.value = structuredClone(defaultFormValues);

    try{ 
        await partStore.fetch(storageStore.selectedStorage._id);   
        await storageStore.fetch(storageStore.selectedStorage._id);
    }
    catch(err){
        console.error(err);
    }

    addPartModal.hide();
}

const modalHidden = (event)=>{
    newPartData.value = structuredClone(defaultFormValues);
    emit('done');
}

onMounted(async ()=>{
    const options = {};
    addPartModal = new Modal('#editPartModal', options);
    
    addPartModalRef.value.addEventListener('hidden.bs.modal',modalHidden)
})

onBeforeUnmount(async ()=>{
    addPartModalRef.value.removeEventListener('hidden.bs.modal',modalHidden)
})
</script>
<template lang="pug">

div.modal.fade#editPartModal( ref="addPartModalRef" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="editPartModalLabel" aria-hidden="true")
    div.modal-dialog
        div.modal-content
            div.modal-header
                h1.modal-title.fs-5#editPartModalLabel Edit Part
                button.btn-close(type="button" data-bs-dismiss="modal" aria-label="Close")

            div.modal-body
                div.mb-3
                    label.form-label(for="newPartName") Name
                    input#newPartName.form-control(v-model="newPartData.name")
                div.mb-3
                    label.form-label(for="newPartName") Description
                    textarea#newPartDescription.form-control(rows="3" v-model="newPartData.description")
                div.mb-3
                    label.form-label(for="newPartNumber") Part Number
                    input#newPartNumber.form-control(v-model="newPartData.part_number")
                div.mb-3
                    label.form-label(for="newPartReference") Reference
                    input#newPartReference.form-control(v-model="newPartData.reference")
                div.mb-3
                    div.row
                        div.col
                            StorageSelector(label="Location" mode="full" v-model="newPartData.location")
                div.mb-3
                    div.row
                        div.col-4
                            label.form-label(for="newPartName") Initial Quantity
                            input#newPartName.form-control(type="number" value="0" v-model="newPartData.qty")
                        div.col-4
                            label.form-label(for="newPartName") Minimum Quantity
                            input#newPartName.form-control(type="number" value="0" v-model="newPartData.min_qty")

                div.mb-3
                    label.form-label(for="newPartName") Keywords
                    input#newPartName.form-control(v-model="newPartData.keywords")

                div.mb-3
                    label.form-label(for="formFileMultiple") Attachments
                    input#formFileMultiple.form-control(type="file" multiple)

            div.modal-footer
                button.btn.btn-primary(type="button" @click="addNewPart()") Save
                button.btn.btn-outline-secondary(type="button" data-bs-dismiss="modal") Cancel
</template>